import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/:code(B[0-9]{9})', name: 'home', component: () => import('@/views/TraceView.vue'), meta: { title: '酒类溯源系统' } },
  { path: '/:code(C[0-9]{9})', name: 'home', component: () => import('@/views/TraceView.vue'), meta: { title: '酒类溯源系统' } },
  { path: '/:code([A-Z0-9]{4}[0-9]{5}[A-Z0-9])', name: 'home', component: () => import('@/views/TraceView.vue'), meta: { title: '酒类溯源系统' } },
  { path: '/visit/:code([A-Z0-9]{4}[0-9]{5}[A-Z0-9])', name: 'visit', component: () => import('@/views/Visit.vue'), meta: { title: '访问记录' } },
  { path: '/qrcode', name: 'qrcode', component: () => import('@/views/QrCodeView.vue'), meta: { title: '溯源码生成器' } },
  { path: '/:pathParam(.*)*', name: 'index', component: () => import('@/views/Index.vue'), meta: { title: '方嘉物联溯源' } }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
